<template>
  <div count-down :class="{expired}">{{ expired ? expiredDesc : value }}</div>
</template>

<script>
import { remainDayHms } from '@shared/utils/timeUtils';

export default {
  name: 'CountDown',
  props: {
    time: { type: [String, Number] },
    expiredDesc: { type: String, default: '' },
  },
  data() {
    return {
      intervalId: -1,
      value: null,
      expired: false,
    };
  },
  methods: {
    tick() {
      const dayHms = remainDayHms(this.$store, +this.time);
      if (dayHms) {
        this.value = dayHms.day ? this.$t('date.dayHms', dayHms) : dayHms.hms;
      } else {
        this.expired = true;
        clearInterval(this.intervalId);
      }
    },
  },
  created() {
    this.tick();
  },
  mounted() {
    this.intervalId = setInterval(() => this.tick(), 1000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
