<template>
  <notification-item cr-match-start-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t('_.statusMessage', { title: arena.title }) }}</p>
    <ul class="verse-con">
      <li>
        <Avatar :info="home" />
        <span>{{ home.name }}</span>
      </li>
      <li>VS</li>
      <li>
        <Avatar :info="away" />
        <span>{{ away.name }}</span>
      </li>
    </ul>
    <CountDown v-if="waitingEnd" :time="waitingEnd" expired-desc="" />
    <template v-slot:buttons>
      <button v-if="!isPassed" @click="goLobby">{{ $t(`_.ok`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import Specific from '@shared/types/Specific';
import CountDown from '@shared/components/common/CountDown.vue';
import { isPassed } from '@shared/utils/timeUtils';
import NotificationItem from '@shared/components/notification/NotificationItem.vue';
import Avatar from '@shared/components/common/Avatar.vue';

export default {
  name: 'CrMatchStartItem',
  components: { Avatar, CountDown, NotificationItem },
  lexicon: 'shared.notification.CR_MATCH_START',
  props: {
    message: Specific,
  },
  data() {
    return {
    };
  },
  computed: {
    arena() {
      return this.message.data.arena || {};
    },
    matchData() {
      return this.message.data.match || {};
    },
    application() {
      return this.message?.data?.application || {};
    },
    home() {
      return this.matchData.home?.team || {};
    },
    away() {
      return this.matchData.away?.team || {};
    },
    setInfo() {
      return this.matchData?.set || {};
    },
    isPassed() {
      return this.waitingEnd ? isPassed(this.$store, this.waitingEnd) : true;
    },
    waitingEnd() {
      return this.setInfo.checkinDeadlineDatetime;
    },
  },
  methods: {
    goLobby() {
      const { arenaId, isWcgArena } = this.arena;
      const { stageId } = this.matchData;
      const { matchId } = this.matchData;

      this.$emit('read-item', this.message || {});
      window.location.href = `${process.env.VUE_APP_HOME_URL}${this.$i18n('path')}/arena/${isWcgArena ? 'wcg/' : ''}${arenaId}/bracket?openMatch=${matchId}&stage-id=${stageId}`;
      this.close();
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
[cr-match-start-item] {
  .verse-con { .m(15, 0); .p(4); .bgc(#2c2c2c); .br(10); .crop;
    > li { .rel; .flex; .items-center; .justify-center; .p(4, 0); .gap(4); .bgc(rgba(255, 255, 255, 0.1));
      [avatar] { .wh(24); }
      > span { .fs(13, 19); .c(#fff); .ellipsis(1); }

      &:nth-of-type(1) { border-radius: 10px 10px 4px 4px; }
      &:nth-of-type(2) { .p(9, 0); .fs(13); .medium; .bgc(transparent); }
      &:nth-of-type(3) { border-radius: 4px 4px 10px 10px; }
    }
  }
}

</style>
